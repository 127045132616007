.Toastify__toast {
    background-color: #141414;
}

.Toastify__toast-body {
    background-color: #141414;
}
.Toastify__close-button {
    color: white;
}
