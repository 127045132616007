@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply dark:bg-darkpool-dark;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input:focus {
    outline: none;
}
* {
    box-sizing: border-box;
}
